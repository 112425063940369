import React, { useState, useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import { SnackbarProvider } from "notistack"
import axios from "axios"
import { useTranslation } from "react-i18next"
import MuiAlert from "@material-ui/lab/Alert"
import TrackingSlider from "@material-ui/core/Slider"

import {
  Button,
  makeStyles,
  Grid,
  TextField,
  FormControl,
  ButtonGroup,
  Snackbar,
  CircularProgress,
  Typography,
  Box,
  Container,
  Tab,
  Tabs,
  Paper,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core"

import SEO from "../components/seo"
import Sigin from "../components/signin"
import Chart from "../components/chart.js"
import BookListAgent from "../components/book-list-agent"
import PageTitle from "../components/page-title"
import Breadcrumbs from "../components/breadcrumbs"

import box from "../../static/images/Icons/box.svg"
import Done from "../../static/images/Icons/done.svg"
import phone from "../../static/images/Icons/phone.svg"
import boxBlue from "../../static/images/Icons/box-blue.svg"
import UserImage from "../../static/images/user-picture.png"
import phoneBlue from "../../static/images/Icons/phone-blue.svg"
import PostOffice from "../../static/images/Icons/post-office.svg"
import DeliveryTruck from "../../static/images/Icons/delivery-truck.svg"
import PostOfficeBlue from "../../static/images/Icons/post-office-blue.svg"
import DeliveryTruckBlue from "../../static/images/Icons/delivery-truck-blue.svg"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function ButtonLink(props) {
  const { icon, primary, to } = props
  const classes = useStyles()

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to]
  )

  return (
    <Button
      component={renderLink}
      className={`${classes.headerMenu} ${classes.headerMenuBtnsHover} ${classes.headerBtn}`}
      startIcon={icon}
    >
      {primary}
    </Button>
  )
}

ButtonLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  headerBtn: {
    color: "#000",
    fontSize: "1rem",
  },
  notSignedIn: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    minHeight: "26vh",
    "& .MuiButtonBase-root": {
      backgroundColor: "#003e96",
      padding: "6px 12px",
      borderRadius: 10,
    },
  },
  dialogOpenBtn: {
    color: "#000",
  },
  formConrolSignup: {
    padding: 0,
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
    "& input": {
      padding: "10px 12px",
      backgroundColor: "#fff",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 13px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& input#userName:hover": {
      cursor: "not-allowed",
    },
  },
  form: {
    "& .react-tel-input": {
      marginBottom: 15,
      direction: "ltr",
      flip: false,
      "& input": {
        width: "100%",
      },
      "& .react-tel-input .form-control": {
        width: "100% important",
      },
    },
  },
  textCenter: {
    textAlign: "center",
  },
  buttonSignupSite: {
    marginTop: 30,
    marginBottom: 30,
  },
  profileGridContainer: {
    "&:first-child": {
      alignItems: "stretch",
    },
    "&:last-child": {
      alignItems: "initial",
    },
  },
  sideBarGrid: {
    backgroundColor: "#f5f5f5",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      "&:after": {
        content: "''",
        backgroundImage: "linear-gradient(180deg, #f5f5f5, transparent)",
        position: "absolute",
        left: 0,
        bottom: "-50px",
        height: 50,
      },
    },
    [theme.breakpoints.down("sm")]: {
      order: 2,
      paddingBottom: "2rem",
      "&:after": {
        content: "",
        display: "none",
      },
    },
  },
  contentGrid: {
    // backgroundImage: `url(${MainBg})`,
    // backgroundColor: "#fff",
    paddingTop: theme.spacing(5),
    paddingBottom: "7rem",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "3rem",
    },
  },
  myProfileTabs: {
    marginTop: "2rem",
    "& .MuiButtonBase-root span": {
      color: "#707070",
      alignItems: "flex-start",
      textAlign: "left",
    },
    "& .Mui-selected.MuiButtonBase-root span": {
      color: "#003e96",
    },
    "& .MuiTabs-flexContainer + span.MuiTabs-indicator": {
      backgroundColor: "#003e96",
    },
  },
  subscriptionPackagesPaper: {
    borderRadius: 10,
    margin: 6,
    padding: 6,
    textAlign: "center",
    borderWidth: 3,
    borderStyle: "solid",
  },
  subscriptionPackagesGrid: {
    "&>div .MuiPaper-root": {
      borderColor: "#93278f",
    },
    "&>div:first-child .MuiPaper-root": {
      borderColor: "#f7931e",
    },
    "&>div:last-child .MuiPaper-root": {
      borderColor: "#ed1c24",
    },
    "&>div .MuiPaper-root .MuiButtonBase-root": {
      backgroundColor: "#93278f",
      fontSize: 11,
      color: "#fff",
    },
    "&>div:first-child .MuiPaper-root .MuiButtonBase-root": {
      backgroundColor: "#f7931e",
    },
    "&>div:last-child .MuiPaper-root .MuiButtonBase-root": {
      backgroundColor: "#ed1c24",
    },
  },
  tableGrid: {
    "& #vertical-tabpanel-1 > .MuiBox-root": {
      padding: 0,
    },
  },
  packageDetails: {
    margin: "0.3rem 0 0.4rem",
  },
  subscriptionCurrentPackagesPaper: {
    borderRadius: 10,
    margin: 6,
    padding: 6,
    textAlign: "center",
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: "#8cc63f",
  },
  currentPackageTextGreen: {
    color: "#8cc63f",
    fontSize: 18,
  },
  currentPackageTextBlue: {
    color: "#003e96",
    fontWeight: 600,
    fontSize: 18,
  },
  trackingSlider: {
    height: 34,
    marginTop: "-16px",
    width: "97%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-40px",
    },
    "& .MuiSlider-rail": {
      height: 34,
      color: "#e5e5e5",
      opacity: 1,
      borderRadius: 30,
      left: "51.6%",
      transform: "translateX(-50%)",
      width: "108%",
    },
    "& .MuiSlider-track": {
      marginTop: "1rem",
      marginLeft: 9,
    },
    "& .MuiSlider-thumb": {
      width: 20,
      height: 20,
      color: "#8cc63f",
      marginTop: 6,
      marginLeft: 0,
    },
    "& .MuiSlider-mark": {
      width: 20,
      height: 20,
      color: "#b7b7b7",
      marginTop: 6,
      borderRadius: 50,
      opacity: 1,
      backgroundColor: "#b7b7b7",
    },
    "& .MuiSlider-markLabel": {
      maxWidth: 48,
    },
    "& .MuiSlider-markLabel img": {
      maxWidth: 48,
    },
  },
  trackingSliderContainer: {
    height: 34,
    color: "#e5e5e5",
    opacity: 1,
    borderRadius: 30,
    marginRight: "-17px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },
  trackingSliderImages: {
    width: "97%",
    height: 50,
    marginTop: "-37px",
    marginLeft: "-19px",
    marginBottom: 50,
    "& .MuiSlider-rail": {
      height: 50,
      color: "transparent",
      opacity: 1,
      borderRadius: 30,
      left: "51.6%",
      transform: "translateX(-50%)",
      width: "108%",
    },
    "& .MuiSlider-track": {
      display: "none",
    },
    "& .MuiSlider-thumb": {
      width: 24,
      height: 24,
      color: "transparent",
      backgroundImage: `url(${Done})`,
      marginLeft: "-2px",
      marginTop: "-8px",
      backgroundPosition: "center",
      backgroundSize: "contain",
    },
    "& .MuiSlider-mark": {
      width: 56,
      height: 56,
      color: "transparent",
      backgroundColor: "transparent",
      backgroundPosition: "center",
      backgroundSize: "contain",
      [theme.breakpoints.down("sm")]: {
        width: 36,
        height: 36,
      },
    },
    "& .MuiSlider-markLabel": {
      maxWidth: 48,
    },
    "& .MuiSlider-markLabel img": {
      maxWidth: 48,
    },
    "& .MuiSlider-mark[data-index='0']": {
      backgroundImage: `url(${phone})`,
    },
    "& .MuiSlider-mark[data-index='2']": {
      backgroundImage: `url(${PostOffice})`,
    },
    "& .MuiSlider-mark[data-index='1'], .MuiSlider-mark[data-index='3']": {
      backgroundImage: `url(${DeliveryTruck})`,
    },
    "& .MuiSlider-mark[data-index='4']": {
      backgroundImage: `url(${box})`,
    },
    "& .MuiSlider-mark.MuiSlider-markActive[data-index='0']": {
      backgroundImage: `url(${phoneBlue})`,
    },
    "& .MuiSlider-mark.MuiSlider-markActive[data-index='2']": {
      backgroundImage: `url(${PostOfficeBlue})`,
    },
    "& .MuiSlider-mark.MuiSlider-markActive[data-index='1'], .MuiSlider-mark.MuiSlider-markActive[data-index='3']": {
      backgroundImage: `url(${DeliveryTruckBlue})`,
    },
    "& .MuiSlider-mark.MuiSlider-markActive[data-index='4']": {
      backgroundImage: `url(${boxBlue})`,
    },
  },
  trackingSliderContainerImages: {
    height: 50,
    opacity: 1,
    borderRadius: 30,
    marginRight: "-17px",
    marginTop: 50,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 28,
      marginRight: 20,
    },
  },
  trackingSliderTab: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: 26,
    },
    "& .MuiBox-root, .MuiTypography-root, .MuiTypography-root:second-child": {
      width: "100%",
    },
  },
  textColorBlue: {
    color: "#018fff",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {},
  },
  trackingOrderDetails: {
    padding: 24,
    backgroundColor: "#f5f5f5",
  },
  orderDetailsBox: {
    textAlign: "right",
  },
  bookItem: {
    textAlign: "center",
    outlineWidth: 0,
    marginBottom: "1.5rem",
    "&:focus": {
      outlineWidth: 0,
    },
    "& *": {
      outlineWidth: 0,
    },
    "& img": {
      maxWidth: 175,
      maxHeight: 244,
      textAlign: "center",
      borderRadius: 10,
    },
  },
  myProfileSecondTabs: {
    marginTop: 0,
    marginBottom: "2rem",
  },
  formControlRadio: {
    float: "left",
    textAlign: "left",
  },
  bookImageUpload: {
    textAlign: "left",
  },
  formLabels: {
    textAlign: "left",
    lineHeight: 1.6,
  },
  resetContainer: {
    textAlign: "center",
  },
}))

const Admin = ({ pageContext }) => {
  const classes = useStyles()
  const [t, i18n] = useTranslation()
  const { pLang } = pageContext

  const [openAlertSuccess, setOpenAlertSuccess] = useState(false)
  const [openAlertSuccessBook, setOpenAlertSuccessBook] = useState(false)
  const [openAlertErrorOnSubmit, setOpenAlertErrorOnSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [slidersValue, setSlidersValue] = useState(10)
  const [activeStepBookWizard, setActiveStepBookWizard] = useState(0)
  const [stateData /* , setStateData */] = useState({})
  const [sendingError, setSendingError] = useState(false)
  const [agentbooks, setAgentBooks] = useState()

  const [userValues, setUserValues] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    profileImage: "",
    passwordOld: "",
    passwordNew: "",
    passwordNewConfirm: "",
    subscription: "",
    favBooks: [],
  })
  const [orderBookFormValues, setOrderBookFormValues] = useState({
    authorName: "",
    bookName: "",
    inspectorName: "",
    publisherName: "",
    ISBN: "",
    type: "",
    introText: "",
    cover: "",
    publicationYear: "",
    weight: "",
    height: "",
    width: "",
    pagesNumber: "",
    categories: "",
    image: "",
  })

  const stepsBookWizard = getSteps()
  const myForm0 = useRef(null)
  const myForm1 = useRef(null)
  const myForm2 = useRef(null)
  const myForm3 = useRef(null)
  const imageRef = useRef(null)
  const derailsFile2 = useRef(null)
  const bookFile = useRef(null)

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(pLang, `admin`, `/${i18n.language}/admin`)
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(
          pLang,
          `admin`,
          `/${i18n.language}/admin`
        )
      } else {
        documentGlobal.location.href = `/${i18n.language}/admin`
      }
    })
  }, [i18n, pLang])

  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue)
  }

  let jwtForProfile
  let roleForProfile
  if (windowGlobal) {
    jwtForProfile = windowGlobal.sessionStorage.getItem("jwtCode")
    roleForProfile = windowGlobal.sessionStorage.getItem("userRl")
  }

  const breadcrumbs = [
    {
      text: t("admin.title"),
    },
  ]

  const handleChange = prop => event => {
    setOrderBookFormValues({
      ...orderBookFormValues,
      [prop]: event.target.value,
    })
    // setCheckBeforeSubmit(true)
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenAlertSuccess(false)
    // setOpenAlertError(false)
    setOpenAlertSuccessBook(false)
    setOpenAlertErrorOnSubmit(false)
  }

  const signout = () => {
    windowGlobal.sessionStorage.removeItem("jwtCode")
    windowGlobal.sessionStorage.removeItem("userId")
    windowGlobal.sessionStorage.removeItem("userRl")
    navigate(`/`)
  }

  useEffect(() => {
    if (jwtForProfile) {
      axios
        .get(`${process.env.GATSBY_API_URL}/users/me`, {
          headers: { Authorization: `Bearer ${jwtForProfile}` },
        })
        .then(res => {
          const data = res.data
          setUserValues({
            userName: data.username,
            lastName: data.lastName,
            firstName: data.firstName,
            profileImage: data.avatar,
            subscription: data.subscription,
            favBooks: data.favoriteBooks,
          })
        })
    }
  }, [jwtForProfile])

  useEffect(() => {
    setSlidersValue(90)
  }, [slidersValue])

  const isMobile = useMediaQuery("(max-width:959.95px)")

  const handleBookWizardNext0 = () => {
    setLoading(true)
    if (!myForm0.current.checkValidity()) {
      setOpenAlertErrorOnSubmit(true)
      setTimeout(() => {
        setLoading(false)
      }, 200)
      return
    }
    setActiveStepBookWizard(prevActiveStep => prevActiveStep + 1)
    setLoading(false)
  }
  const handleBookWizardNext1 = () => {
    setLoading(true)
    if (!myForm1.current.checkValidity()) {
      setOpenAlertErrorOnSubmit(true)
      setTimeout(() => {
        setLoading(false)
      }, 200)
      return
    }
    setActiveStepBookWizard(prevActiveStep => prevActiveStep + 1)
    setLoading(false)
  }
  const handleBookWizardNext2 = () => {
    setLoading(true)
    if (!myForm2.current.checkValidity()) {
      setOpenAlertErrorOnSubmit(true)
      setTimeout(() => {
        setLoading(false)
      }, 200)
      return
    }
    setActiveStepBookWizard(prevActiveStep => prevActiveStep + 1)
    setLoading(false)
  }
  // const handleBookWizardNext3 = () => {
  //   setLoading(true)
  //   if (!myForm3.current.checkValidity()) {
  //     setOpenAlertErrorOnSubmit(true)
  //     setTimeout(() => {
  //       setLoading(false)
  //     }, 200)
  //     return
  //   }
  //   setActiveStepBookWizard(prevActiveStep => prevActiveStep + 1)
  //   setLoading(false)
  // }

  const handleBookWizardBack = () => {
    setActiveStepBookWizard(prevActiveStep => prevActiveStep - 1)
  }

  const handleBookWizardReset = () => {
    setActiveStepBookWizard(0)
  }
  function getSteps() {
    return [
      t("admin.bookDetails"),
      t("admin.bookDetails"),
      t("admin.bookDetails"),
      t("admin.bookDetails"),
    ]
  }

  const openFile = function (event) {
    let input = event.target
    let reader = new FileReader()
    reader.onload = function () {
      let dataURL = reader.result
      let output = documentGlobal.getElementById("output")
      output.src = dataURL
      // setBookImage({ reader })
    }
    reader.readAsDataURL(input.files[0])
    // setImage(event.target.files[0])
  }

  if (documentGlobal) {
    if (documentGlobal.getElementById("weight")) {
      documentGlobal
        .getElementById("weight")
        .addEventListener("keypress", function (evt) {
          if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
            evt.preventDefault()
          }
        })
    }
    if (documentGlobal.getElementById("height")) {
      documentGlobal
        .getElementById("height")
        .addEventListener("keypress", function (evt) {
          if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
            evt.preventDefault()
          }
        })
    }
    if (documentGlobal.getElementById("width")) {
      documentGlobal
        .getElementById("width")
        .addEventListener("keypress", function (evt) {
          if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
            evt.preventDefault()
          }
        })
    }
    if (documentGlobal.getElementById("pagesNumber")) {
      documentGlobal
        .getElementById("pagesNumber")
        .addEventListener("keypress", function (evt) {
          if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
            evt.preventDefault()
          }
        })
    }
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Grid item xs={12} md={12} className={classes.textCenter}>
            <FormControl
              fullWidth
              className={`${classes.margin} ${classes.formConrolSignup}`}
            >
              <TextField
                required
                fullWidth
                id="bookName"
                type="text"
                label={t("orderBook.bookName")}
                variant="outlined"
                value={orderBookFormValues.bookName}
                onChange={handleChange("bookName")}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={`${classes.margin} ${classes.formConrolSignup}`}
            >
              <TextField
                required
                fullWidth
                id="authorName"
                type="text"
                label={t("orderBook.authorName")}
                variant="outlined"
                value={orderBookFormValues.authorName}
                onChange={handleChange("authorName")}
              />
            </FormControl>
            <FormControl
              className={`${classes.margin} ${classes.formConrolSignup}`}
              fullWidth
            >
              <TextField
                required
                fullWidth
                id="inspectorName"
                type="text"
                label={t("orderBook.inspectorName")}
                variant="outlined"
                value={orderBookFormValues.inspectorName}
                onChange={handleChange("inspectorName")}
              />
            </FormControl>
            <FormControl
              className={`${classes.margin} ${classes.formConrolSignup}`}
              fullWidth
            >
              <TextField
                required
                id="publisherName"
                name="publisherName"
                label={t("orderBook.publisherName")}
                variant="outlined"
                value={orderBookFormValues.publisherName}
                onChange={handleChange("publisherName")}
              />
            </FormControl>
          </Grid>
        )
      case 1:
        return (
          <Grid item xs={12} md={12} className={classes.textCenter}>
            <FormControl
              fullWidth
              className={`${classes.margin} ${classes.formConrolSignup}`}
            >
              <TextField
                required
                fullWidth
                id="ISBN"
                type="text"
                label="ISBN"
                variant="outlined"
                value={orderBookFormValues.ISBN}
                onChange={handleChange("ISBN")}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={`${classes.margin} ${classes.formConrolSignup}`}
            >
              <TextField
                required
                id="introText"
                type="textarea"
                multiline /* ="true" */
                rows="3"
                rowsMax="6"
                label={t("admin.introText")}
                variant="outlined"
                value={orderBookFormValues.introText}
                onChange={handleChange("introText")}
              />
            </FormControl>
            <FormControl
              className={`${classes.margin} ${classes.formConrolSignup}`}
              fullWidth
            >
              <TextField
                required
                id="cover"
                name="inspectorName"
                label={t("admin.cover")}
                variant="outlined"
                value={orderBookFormValues.cover}
                onChange={handleChange("cover")}
              />
            </FormControl>
            {/* <FormControl
              className={`${classes.margin} ${classes.formConrolSignup}`}
              fullWidth
            >
              <TextField
                required
                id="type"
                name="inspectorName"
                label={t("admin.type")}
                variant="outlined"
                value={orderBookFormValues.type}
                onChange={handleChange("type")}
              />
            </FormControl> */}
          </Grid>
        )
      case 2:
        return (
          <Grid item xs={12} md={12} className={classes.textCenter}>
            <FormControl
              fullWidth
              className={`${classes.margin} ${classes.formConrolSignup}`}
            >
              <TextField
                required
                fullWidth
                id="weight"
                type="text"
                label={t("admin.weight")}
                variant="outlined"
                value={orderBookFormValues.weight}
                onChange={handleChange("weight")}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={`${classes.margin} ${classes.formConrolSignup}`}
            >
              <TextField
                required
                id="height"
                type="text"
                label={t("admin.height")}
                variant="outlined"
                value={orderBookFormValues.height}
                onChange={handleChange("height")}
              />
            </FormControl>
            <FormControl
              className={`${classes.margin} ${classes.formConrolSignup}`}
              fullWidth
            >
              <TextField
                required
                id="width"
                label={t("admin.width")}
                variant="outlined"
                value={orderBookFormValues.width}
                onChange={handleChange("width")}
              />
            </FormControl>
            <FormControl
              className={`${classes.margin} ${classes.formConrolSignup}`}
              fullWidth
            >
              <TextField
                required
                id="pagesNumber"
                label={t("admin.pagesNumber")}
                variant="outlined"
                value={orderBookFormValues.pagesNumber}
                onChange={handleChange("pagesNumber")}
              />
            </FormControl>
          </Grid>
        )
      case 3:
        return (
          <Grid item xs={12} md={12} className={classes.textCenter}>
            <FormControl
              fullWidth
              className={`${classes.margin} ${classes.formConrolSignup}`}
            >
              <TextField
                required
                fullWidth
                id="categories"
                type="text"
                label={t("admin.categories")}
                variant="outlined"
                value={orderBookFormValues.categories}
                onChange={handleChange("categories")}
              />
            </FormControl>
            <FormControl
              className={`${classes.margin} ${classes.formConrolSignup}`}
              fullWidth
            >
              <TextField
                required
                id="publicationYear"
                name="publisherName"
                label={t("admin.publicationYear")}
                variant="outlined"
                value={orderBookFormValues.publicationYear}
                onChange={handleChange("publicationYear")}
              />
            </FormControl>
            <FormControl
              component="fieldset"
              className={`${classes.formControlRadio} ${classes.margin}`}
            >
              <FormLabel className={classes.formLabels}>
                {t("admin.type")}
              </FormLabel>
              <RadioGroup
                aria-label="type"
                required={true}
                name="type1"
                onChange={handleChange("type")}
              >
                <FormControlLabel
                  value="paper"
                  control={<Radio />}
                  label={t("admin.bootTypePaper")}
                />
                <FormControlLabel
                  value="readed"
                  control={<Radio />}
                  label={t("admin.bootTypeReaded")}
                />
                <FormControlLabel
                  value="audio"
                  control={<Radio />}
                  label={t("admin.bootTypeAudio")}
                />
              </RadioGroup>
            </FormControl>
            <FormControl
              className={`${classes.margin} ${classes.formConrolSignup}`}
              fullWidth
            >
              <FormLabel className={classes.formLabels} component="label">
                {t("admin.detailFile2")}
              </FormLabel>
              <input
                required
                type="file"
                accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                ref={derailsFile2}
              />
            </FormControl>
            <FormControl
              className={`${classes.margin} ${classes.formConrolSignup}`}
              fullWidth
            >
              <FormLabel className={classes.formLabels} component="label">
                {t("admin.uploadImage")}
              </FormLabel>
              <Grid container>
                <Grid item xs={12} sm={6} className={classes.bookImageUpload}>
                  <input
                    required
                    type="file"
                    accept="image/*"
                    ref={imageRef}
                    onChange={event => openFile(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.bookItem}>
                  <img id="output" alt="output" />
                </Grid>
              </Grid>
            </FormControl>
            {orderBookFormValues.type && orderBookFormValues.type !== "paper" && (
              <FormControl
                className={`${classes.margin} ${classes.formConrolSignup}`}
                fullWidth
              >
                <FormLabel className={classes.formLabels} component="label">
                  {t("admin.bookFile")}
                </FormLabel>
                <input
                  required
                  type="file"
                  accept={`${
                    orderBookFormValues.type === "audio"
                      ? "audio/*"
                      : ".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                  }`}
                  ref={bookFile}
                />
              </FormControl>
            )}
          </Grid>
        )
      default:
        return "Unknown step"
    }
  }

  const handleClickWizardButton = () => {
    setLoading(true)
    setSending(true)
    setSendingError(false)
    if (!myForm3.current.checkValidity()) {
      setOpenAlertErrorOnSubmit(true)
      setTimeout(() => {
        setLoading(false)
      }, 200)
      return
    }
    if (myForm3.current.checkValidity()) {
      let formData
      formData = new FormData()
      if (imageRef.current) {
        formData.append(
          "files.image",
          imageRef.current && imageRef.current.files[0]
        )
      }
      if (bookFile.current) {
        formData.append(
          "files.file",
          bookFile.current && bookFile.current.files[0]
        )
      }

      let data = {}

      data["name"] = orderBookFormValues.bookName
      data["author"] = orderBookFormValues.authorName
      data["inspector"] = orderBookFormValues.inspectorName
      data["publisher"] = orderBookFormValues.publisherName

      data["isbn"] = orderBookFormValues.ISBN
      data["intro_text"] = orderBookFormValues.introText
      data["cover"] = orderBookFormValues.cover
      data["type"] = orderBookFormValues.type

      data["weight"] = orderBookFormValues.weight
      data["height"] = orderBookFormValues.height
      data["width"] = orderBookFormValues.width
      data["number_of_pages"] = orderBookFormValues.pagesNumber

      data["category"] = orderBookFormValues.categories
      data["publication_year"] = orderBookFormValues.publicationYear

      formData.append("data", JSON.stringify(data))

      axios
        .post(`${process.env.GATSBY_API_URL}/add-agent-book`, formData, {
          headers: {
            Authorization: `Bearer ${jwtForProfile}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          setOrderBookFormValues({
            authorName: "",
            bookName: "",
            inspectorName: "",
            publisherName: "",
            ISBN: "",
            type: "",
            introText: "",
            cover: "",
            publicationYear: "",
            weight: "",
            height: "",
            width: "",
            pagesNumber: "",
            categories: "",
            image: "",
          })
          setSending(false)
          setOpenAlertSuccessBook(true)
          setTimeout(() => {
            setOpenAlertSuccessBook(false)
          }, 2000)
        })
        .catch(err => {
          setSending(false)
          setSendingError(true)
        })
    }
    setActiveStepBookWizard(prevActiveStep => prevActiveStep + 1)
    setLoading(false)
  }
  useEffect(() => {
    axios.get(`${process.env.GATSBY_API_URL}/agent-books`).then(res => {
      setAgentBooks(res.data)
    })
  }, [])

  return (
    <>
      <SEO title="admin" lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <PageTitle
        subTitle={t("admin.title")}
        image={
          userValues.profileImage
            ? `${process.env.GATSBY_API_URL}${userValues.profileImage.url}`
            : UserImage
        }
        border={true}
      />
      <Box
        component="div"
        display={jwtForProfile && roleForProfile === "agent" ? `block` : `none`}
      >
        <Grid container className={classes.profileGridContainer}>
          {!isMobile && (
            <Grid
              item
              sm={12}
              xs={12}
              md={3}
              className={classes.sideBarGrid}
              component="div"
            >
              <Container className={classes.sideBarContainer}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={tabValue}
                  onChange={handleChangeTabs}
                  aria-label="Vertical tabs example"
                  className={classes.myProfileTabs}
                >
                  <Tab label={t("admin.addNewBook")} />
                  <Tab
                    value={false}
                    label={t("admin.myProfile")}
                    onClick={() => {
                      navigate(`/${i18n.language}/my-profile`)
                    }}
                  />
                  <Tab label={t("admin.myBooks")} />
                  <Tab label={t("admin.statistics")} />
                  <Tab label={t("admin.balance")} />
                </Tabs>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={false}
                  className={`${classes.myProfileTabs} ${classes.myProfileSecondTabs}`}
                >
                  <Tab label={t("myProfile.signoutBtn")} onClick={signout} />
                </Tabs>
              </Container>
            </Grid>
          )}
          <Grid
            item
            container
            sm={12}
            md={9}
            className={classes.contentGrid}
            component="div"
          >
            <Grid item xs={12} sm={12} md={8} className={classes.tableGrid}>
              {!isMobile && (
                <>
                  <TabPanel value={tabValue} index={0}>
                    <Stepper
                      activeStep={activeStepBookWizard}
                      orientation="vertical"
                    >
                      {stepsBookWizard.map((label, index) => (
                        <Step key={index}>
                          <StepLabel>{label}</StepLabel>
                          <StepContent>
                            <form
                              action="/"
                              method="POST"
                              ref={
                                index === 0
                                  ? myForm0
                                  : index === 1
                                  ? myForm1
                                  : index === 2
                                  ? myForm2
                                  : index === 3
                                  ? myForm3
                                  : ""
                              }
                            >
                              <div>{getStepContent(index)}</div>
                              <div className={classes.actionsContainer}>
                                <div>
                                  <ButtonGroup
                                    color="primary"
                                    aria-label="Create account"
                                    // disabled={disabledBtn}
                                    loading={loading.loading}
                                    className={`${classes.button} ${classes.margin} ${classes.buttonSignupSite} button`}
                                  >
                                    <Button
                                      disabled={activeStepBookWizard === 0}
                                      onClick={handleBookWizardBack}
                                      className={classes.button}
                                    >
                                      {t("admin.back")}
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        index === 0
                                          ? handleBookWizardNext0()
                                          : index === 1
                                          ? handleBookWizardNext1()
                                          : index === 2
                                          ? handleBookWizardNext2()
                                          : index === 3
                                          ? handleClickWizardButton()
                                          : ""
                                      }
                                      className={classes.createAccountBtn}
                                    >
                                      {loading && (
                                        <CircularProgress size={26} />
                                      )}
                                      {!loading &&
                                        (activeStepBookWizard ===
                                        stepsBookWizard.length - 1
                                          ? t("admin.finish")
                                          : t("admin.next"))}
                                    </Button>
                                  </ButtonGroup>
                                </div>
                              </div>
                            </form>
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>
                    {activeStepBookWizard === stepsBookWizard.length && (
                      <Paper
                        square
                        elevation={0}
                        className={classes.resetContainer}
                      >
                        {!sendingError && (
                          <>
                            {sending && (
                              <>
                                <Typography>
                                  {t("admin.requestSending")}
                                </Typography>
                                {/* <Typography> */}
                                  <CircularProgress
                                    size={50}
                                    style={{color: "black" }}
                                  />
                                {/* </Typography> */}
                              </>
                            )}
                            {!sending && (
                              <Typography>{t("admin.bookAdded")}</Typography>
                            )}
                          </>
                        )}
                        {sendingError && (
                          <>
                            <Typography>{t("admin.sendingError")}</Typography>
                          </>
                        )}
                        <Button
                          onClick={handleBookWizardReset}
                          className={classes.button}
                        >
                          {t("admin.resetWizard")}
                        </Button>
                      </Paper>
                    )}
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    <BookListAgent books={agentbooks} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={3}>
                    <Chart data={stateData} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={4}>
                    Balance
                  </TabPanel>
                  <TabPanel
                    value={tabValue}
                    index={5}
                    className={classes.trackingSliderTab}
                  >
                    <Typography
                      variant="h5"
                      component="p"
                      className={classes.textColorBlue}
                    >
                      {t("myProfile.shipmentMoves")}
                    </Typography>
                    <div className={classes.trackingSliderContainerImages}>
                      <TrackingSlider
                        className={classes.trackingSliderImages}
                        defaultValue={slidersValue}
                        step={20}
                        marks
                        min={10}
                        max={90}
                        disabled
                      />
                    </div>
                    <div className={classes.trackingSliderContainer}>
                      <TrackingSlider
                        className={classes.trackingSlider}
                        defaultValue={slidersValue}
                        step={20}
                        marks
                        min={10}
                        max={90}
                        disabled
                      />
                    </div>

                    <br />
                    <br />
                    <br />
                    <Typography
                      variant="h5"
                      component="p"
                      className={classes.textColorBlue}
                    >
                      {t("myProfile.shipmentMoves")}
                    </Typography>
                    <br />
                    <Paper className={`${classes.trackingOrderDetails} `}>
                      <Grid container>
                        <Grid item xs={7}>
                          <Grid container>
                            <Grid item xs={4}>
                              <Typography variant="subtitle1" component="p">
                                {t("myProfile.writerName")}
                              </Typography>
                              <Typography variant="subtitle1" component="p">
                                التجويد المصور
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="subtitle1" component="p">
                                {t("myProfile.count")}
                              </Typography>
                              <Typography variant="subtitle1" component="p">
                                1
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="subtitle1" component="p">
                                {t("myProfile.subtotal")}
                              </Typography>
                              <Typography variant="subtitle1" component="p">
                                217.77
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={5}>
                          <Box display="flex" justifyContent="space-around">
                            <Box>
                              <Typography variant="subtitle1" component="p">
                                {t("myProfile.total")}
                              </Typography>
                              <Typography variant="subtitle1" component="p">
                                {t("myProfile.discount")}
                              </Typography>
                              <Typography variant="subtitle1" component="p">
                                {t("myProfile.shippingCost")}
                              </Typography>
                              <Typography variant="subtitle1" component="p">
                                {t("myProfile.grandTotal")}
                              </Typography>
                            </Box>
                            <Box className={classes.orderDetailsBox}>
                              <Typography variant="subtitle1" component="p">
                                217.77
                              </Typography>
                              <Typography variant="subtitle1" component="p">
                                0
                              </Typography>
                              <Typography variant="subtitle1" component="p">
                                0
                              </Typography>
                              <Typography variant="subtitle1" component="p">
                                237.77
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Paper>
                  </TabPanel>
                </>
              )}
              {isMobile && (
                <>
                  <Container>
                    <Typography> {t("admin.openFromPc")} </Typography>
                  </Container>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <SnackbarProvider maxSnack={3}>
          <Snackbar
            open={openAlertSuccess}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="success">
              {t("myProfile.dataChangedsuccess")}
            </Alert>
          </Snackbar>
          <Snackbar
            open={openAlertSuccessBook}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="success">
              {t("admin.bookAdded")}
            </Alert>
          </Snackbar>
          {/* <Snackbar
              open={openAlertError}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error">
                {errorMassegeCatch}
              </Alert>
            </Snackbar> */}
          <Snackbar
            open={openAlertErrorOnSubmit}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              {t("errors.fieldsWrong")}
            </Alert>
          </Snackbar>
        </SnackbarProvider>
      </Box>
      <Box
        component="div"
        display={
          !jwtForProfile || roleForProfile !== "agent" ? `block` : `none`
        }
      >
        <Container className={classes.notSignedIn}>
          <Typography variant="subtitle1">
            {t("myProfile.noPremissions")}
          </Typography>
          <br />
          <Sigin />
        </Container>
      </Box>
    </>
  )
}
export default Admin
