import React from 'react'
import {  Bar } from "react-chartjs-2";

const Chart = ({data}) => {
    const {positive, negative, totalTestResults} = data
    
    const dataStructure = {
        positive,
        negative,
        total: totalTestResults,
        totasl: totalTestResults,
        totaal: totalTestResults,
        totdal: totalTestResults,
    }

    const chartDataConfig = {
        labels: Object.keys(dataStructure),
        datasets: [
            {
              label: 'My First dataset',
              backgroundColor: 'rgba(220,220,220,0.5)',
              borderColor: 'rgba(220,220,220,0.8)',
              hoverBackgroundColor: 'rgba(220,220,220,0.75)',
              hoverBorderColor: 'rgba(220,220,220,1)',
              data: [65, 59, 80, 81, 56, 55, 40],
            },
            {
              label: 'My Second dataset',
              backgroundColor: 'rgba(151,187,205,0.5)',
              borderColor: 'rgba(151,187,205,0.8)',
              hoverBackgroundColor: 'rgba(151,187,205,0.75)',
              hoverBorderColor: 'rgba(151,187,205,1)',
              data: [28, 48, 40, 19, 86, 27, 90],
            },
          ],
    };

    return (
        <div>
            <Bar data={chartDataConfig}/>
        </div>
    )
}

export default Chart
