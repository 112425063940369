import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import MuiAlert from "@material-ui/lab/Alert"

import {
  makeStyles,
  Container,
  Grid,
  Box,
  Button,
  Snackbar,
  Typography,
  Paper,
} from "@material-ui/core"

import BookImage from "../../static/images/book-image.png"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  loadmoreBtnWrap: {
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
  bookItem: {
    margin: "0 auto",
    textAlign: "center",
    outlineWidth: 0,
    marginBottom: "1.5rem",
    "&:focus": {
      outlineWidth: 0,
    },
    "& *": {
      outlineWidth: 0,
      maxWidth: 175,
      textAlign: "center",
      margin: "0 auto",
      textDecoration: "none",
    },
    "& img": {
      textAlign: "center",
      border: "3px solid #000",
      borderRadius: 10,
    },
    "& p": {
      margin: "0 auto",
      fontSize: "1.2rem",
      color: "#003e96",
    },
    "& .add-to-cart-btn": {
      display: "none",
    },
    "& .rating-and-price": {
      marginTop: 1
    },
    "&:hover .rating-and-price": {
      display: "none",
    },
    "&:hover .add-to-cart-btn": {
      display: "block",
    },
  },
  addToCartBtn: {
    color: "#003e96",
    borderRadius: 10,
    marginTop: "1.1rem",
  },
  addToCartBtnIcon: {
    width: "1.5rem",
    height: "1.5rem",
    fontSize: "1.5rem",
  },
  outOfStock: {
    backgroundColor: "#003e96",
    width: 64,
    padding: 11,
    borderRadius: "50%",
    position: "absolute",
    right: "-30px",
    top: "-30px",
    color: "#fff",
    fontWeight: "600",
  },
  bookItemPaper: {
    position: "relative",
    backgroundColor: "transparent",
    border: 0,
  },
  currencyIcon: {
    width: 14,
    marginBottom: "-2px",
    border: "0 !important",
    borderRadius: "0 !important",
  },
}))

export default function BookList({ props, books }) {
  const classes = useStyles()
  const {t} = useTranslation()
  const [loadedBooks, setLoadedProperties] = React.useState(12)
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false)

  const handleCloseAddToCard = () => {
    setOpenSuccessSnackbar(false)
  }

  const handleLoadMore = () => {
    setLoadedProperties(loadedBooks + 8)
  }

  return (
    <Container className={classes.cardGrid} maxWidth="lg">
      <Grid container spacing={4}>
        {books &&
          books.slice(0, loadedBooks).map((book, key) => (
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              md={6}
              className={classes.bookItem}
              key={book.id}
            >
              <Paper
                variant="outlined"
                square
                className={classes.bookItemPaper}
              >
                  <img
                    src={book.image ? `${process.env.GATSBY_API_URL}${book.image.url}` : BookImage}
                    alt={book.name}
                  />
                  <Typography variant="h5" component="p">
                    {book.name}
                  </Typography>
              </Paper>
            </Grid>
          ))}
      </Grid>
      {books && books.length > loadedBooks && (
        <Box className={classes.loadmoreBtnWrap} p={2}>
          <Button variant="outlined" color="primary" onClick={handleLoadMore}>
            {t("bookList.loadMoreBtn")}
          </Button>
        </Box>
      )}
      <Snackbar open={openSuccessSnackbar}>
        <Alert onClose={handleCloseAddToCard} severity="success">
          {t("book.itemAddedToCart")}
        </Alert>
      </Snackbar>
    </Container>
  )
}
